







import {Component, Vue} from 'vue-property-decorator';

@Component({
  name: 'BankTransfer'
})
export default class BankTransfer extends Vue {
}
