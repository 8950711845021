





















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import Util from '@/utils/Util';
import _ from 'lodash';
import PaymentOptions from '@/components/layouts/PaymentOptions.vue';

declare var WindcavePayments: any;

@Component({
	name: 'windcave',
  components: {
    PaymentOptions,
  }
})
export default class Windcave extends Vue {
	@State private app: any;
  @Prop() private submitApplication!: any;
  @State private proposal: any;
	@Action('proposal/setPaymentLink') private setPaymentLink: any;
	@Action('app/setLoadingText') private setLoadingText: any;
	private sessionId: string = '';
	private hppLink: string = '';
	private seamlessHppLink: string = '';
	private paymentFieldsReady: boolean = true;
	private iframeEl: HTMLIFrameElement | null = null;

	public async getToken() {
		return new Promise((r) => {
			setTimeout(() => {
				r(this.sessionId);
			}, 1000);
		});
	}

  public async validate() {
    return new Promise((resolve, reject) => {
      WindcavePayments.Seamless.validate({
        onProcessed: (isValid) => {
          resolve(isValid);
        },
        onError: (error) => {
          console.error('Failed to validate windcave payment.', error);
          reject(error);
        }
      });
    });
  }

  public async pay() {
    return new Promise((resolve, reject) => {
      WindcavePayments.Seamless.submit({
        onProcessed: (param) => {
          if (param !== '3DSecure') {
            resolve(true);
          }
        },
        onError: (error) => {
          console.error('Failed to pay windcave payment.', error);
          reject(error);
        }
      });
    });
  }

	public paymentFailed() {
		this.createNewPaymentProviderSession();
	}

  private createNewPaymentProviderSession() {
    Util.initWindcave(this.proposal.invoiceId).then(({ data }) => {
      this.sessionId = data.data.sessionId;
      this.hppLink = data.data.hppLink;
      this.seamlessHppLink = data.data.seamlessHppLink;

      if (this.iframeEl) {
        const container = (this.$refs.paymentWrapper as HTMLDivElement);
        WindcavePayments.Seamless.prepareIframe({
          url: this.seamlessHppLink,
          containerId: 'paymentWrapper',
          width: 400,
          height: 470,
          loadTimeout: 30,
          onProcessed: () => {
            this.paymentFieldsReady = true;
          },
          onError: (error) => {
            console.error('Error preparing iframe', error);
            this.paymentFieldsReady = false;
          }
        });
      }

    });
  }

	private mounted() {
		this.iframeEl = document.createElement('iframe') as HTMLIFrameElement;
		this.createNewPaymentProviderSession();
	}

	private beforeDestroy() {
		this.setLoadingText(null);
	}
}
