





















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import Util from '@/utils/Util';
import _ from 'lodash';
import PaymentOptions from '@/components/layouts/PaymentOptions.vue';

@Component({
  name: 'bpoint',
  components: {
    PaymentOptions,
  }
})
export default class BPoint extends Vue {
  @Prop() private config: any;
  @Prop() private submitApplication!: any;
  @State private app: any;
  @Action('app/setLoadingText') private setLoadingText: any;
  private authKey: string = '';
  private paymentFieldsReady: boolean = false;
  private iframeEl: HTMLIFrameElement | null = null;

  public async getToken() {
    return new Promise((r) => {
      setTimeout(() => {
        r(this.authKey);
      }, 1000);
    });
  }

  public paymentFailed() {
    this.setup();
  }

  private setup() {
    const mobile = this.$vuetify.breakpoint.xsOnly;
    Util.initBPoint().then(({ data }) => {
      this.authKey = data.data.authKey;
      if (this.iframeEl) {
        const container = (this.$refs.paymentWrapper as HTMLDivElement);
        const dataAttr = container.getAttributeNames().find((name) => name.indexOf('data-') > -1) || 'data';
        this.iframeEl.src = `https://www.bpoint.com.au/webapi/v3/txns/iframe/${this.authKey}`;
        this.iframeEl.className = 'iframe';
        this.iframeEl.setAttribute(dataAttr, '');
        this.iframeEl.setAttribute('style', `height:${mobile ? 230 : 150}px!important`);
        container.appendChild(this.iframeEl);
        Util.childIFramesLoaded(this.$refs.paymentWrapper, 10000).then(() => {
          this.paymentFieldsReady = true;
        });
      }
    });
  }

  private mounted() {
    this.iframeEl = document.createElement('iframe') as HTMLIFrameElement;
    this.setup();
  }

  private technicalError(error, data) {
    this.$dialog.open({ type: 'technical-issue', info: error, data, level: 'error' });
  }
}

