



































































import { Component, Prop, Vue } from 'vue-property-decorator';
import PaymentOptions from '@/components/layouts/PaymentOptions.vue';

@Component({
  name: 'DummyProvider',
  components: {
    PaymentOptions,
  }
})
export default class DummyProvider extends Vue {
  @Prop() private submitApplication!: any;
  private pan: string = '';
  private exp: string = '';
  private cvd: string = '';

  get Visa() {
    return '/coverhub-portal/images/Visa.svg';
  }

  get Mastercard() {
    return '/coverhub-portal/images/Mastercard.svg';
  }

  get Amex() {
    return '/coverhub-portal/images/Amex.svg';
  }

  public async getToken() {
    if (!this.pan.match(/^\d{15,16}$/)) {
      return { errorMessage: this.$t('proposal.payment.error.cardNumber') };
    }
    if (!this.exp.match(/^\d{4}$/)) {
      return { errorMessage: this.$t('proposal.payment.error.expirationDate') };
    }
    if (!this.cvd.match(/^\d{3,4}$/)) {
      return { errorMessage: this.$t('proposal.payment.error.CVD') };
    }
    return this.pan;
  }

  private onInput(value, field) {
    this[field] = value;
  }
}

