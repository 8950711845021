









import {Component, Vue} from 'vue-property-decorator';

@Component({
  name: 'AgencyInvoicing'
})
export default class AgencyInvoicing extends Vue {
}
