














import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import Util from '@/utils/Util';
import _ from 'lodash';
import PaymentOptions from '@/components/layouts/PaymentOptions.vue';

declare var WindcavePayments: any;

@Component({
	name: 'migs',
  components: {
    PaymentOptions,
  }
})
export default class MIGS extends Vue {
	@State private app: any;
  @Prop() private submitApplication!: any;
  @State private proposal: any;
	@Action('proposal/setPaymentLink') private setPaymentLink: any;
	@Action('app/setLoadingText') private setLoadingText: any;
	@Action('app/setPayButtonVisible') private setPayButtonVisible: any;
	private url: string = '';

	public async validate() {
		return new Promise((resolve, reject) => {
				WindcavePayments.Seamless.validate({
				onProcessed: (isValid) => {
					resolve(isValid);
				},
				onError: (error) => {
					reject();
				}
			});
		});
	}

  public getPaymentLink() {
		return this.url;
  }

  public paymentFailed() {
    this.createNewPaymentProviderSession();
  }

  private pay() {
		if (this.getPaymentLink()) {
			this.setLoadingText({ message: 'Payment in progress' });
			const paymentWindow = window.open(this.getPaymentLink());
			let timer: any = null;
			const self = this;
			const checkStatus = () => {
				if (paymentWindow && paymentWindow.closed) {
					self.setLoadingText(null);
					clearInterval(timer);
				}
			};
			timer = setInterval(checkStatus, 500);
		}
  }

  private createNewPaymentProviderSession() {
		Util.initMIGS(this.proposal.invoiceId).then(({ data }) => {
      this.url = data.data.url;
    });
  }

	private mounted() {
		this.setPayButtonVisible(false);
		this.createNewPaymentProviderSession();
	}

	private beforeDestroy() {
		this.setLoadingText(null);
		this.setPayButtonVisible(true);
	}
}
