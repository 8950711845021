






































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import _ from 'lodash';
import EventBus from '@/common/EventBus';
import { Proposal } from '@/models';
import Benefits from '@/components/products/Benefits.vue';
import PaymentIssue from '@/components/layouts/PaymentIssue.vue';
import PolicyService from '@/services/PolicyService';
import Util from '@/utils/Util';
import GAUtil from '@/utils/GAUtil';
import EWay from '@/views/auth/Portal/PortalStepperItems/PaymentProviders/Eway.vue';
import DummyProvider from '@/views/auth/Portal/PortalStepperItems/PaymentProviders/DummyProvider.vue';
import BPoint from '@/views/auth/Portal/PortalStepperItems/PaymentProviders/BPoint.vue';
import MIGS from '@/views/auth/Portal/PortalStepperItems/PaymentProviders/MIGS.vue';
import Windcave from '@/views/auth/Portal/PortalStepperItems/PaymentProviders/Windcave.vue';
import BankTransfer from '@/views/auth/Portal/PortalStepperItems/PaymentProviders/BankTransfer.vue';
import AgencyInvoicing from '@/views/auth/Portal/PortalStepperItems/PaymentProviders/AgencyInvoicing.vue';
import Illustration from '@/components/common/Illustration.vue';
import {IRawProducts} from '@/interfaces';
import {PaymentProviderModel} from 'shift-policy-service-api-client';

const RENEWAL_LINK_PAID = 'RENEWAL_LINK_PAID';
const CONTINUATION_LINK_PAID = 'CONTINUATION_LINK_PAID';

@Component({
  name: 'Payment',
  components: {
    Benefits,
    PaymentIssue,
    EWay,
    BPoint,
    Windcave,
    MIGS,
    BankTransfer,
    AgencyInvoicing,
    DummyProvider,
    Illustration
  },
})
export default class Payment extends Vue {
  @Prop() private afterUW!: boolean;
  @Prop() private img!: string;
  @State private proposal!: Proposal;
  @State private cms: any;
  @State private app: any;
  @State private products: any;
  @Getter('proposal/getInvoicesPriceToPay') private getInvoicesPriceToPay: any;
  @Getter('proposal/getInvoicesTotalTax') private getInvoicesTotalTax: any;
  @Getter('app/getPaymentProviders') private getPaymentProviders: any;
  @Getter('quotation/getFactValueByFactID') private getFactValueByFactID!: (factID: string) => string;
  @Getter('quotation/getTotalTaxPercentage') private getTotalTaxPercentage!: () => number;
  @Getter('quotation/getAllCustomProductTrees') private getAllCustomProductTrees!: () => any;
  @Getter('products/getAllProductsWithLatestVersion') private getUniqueProducts!: () => IRawProducts;
  @Action('proposal/setPaymentToken') private setPaymentToken: any;
  @Action('proposal/setPaymentSkipped') private setPaymentSkipped: any;
  @Action('app/setPardotAvailability') private setPardotAvailability: any;
  @Action('app/setLoadingText') private setLoadingText: any;

  private paymentProviders: any[] = [];
  private selectedPaymentProvider: any = null;
  private refunds = 0;
  private refundsTax = 0;
  private errorMessage: any = '';
  private tokenGenerationWarning = false;
  private shiftPaymentError = false;
  private alertActive = false;
  private tokenTimer: any;
  private timeout: number = 10000;
  private submittedPayment = false;
  private showPaymentIssue = true;
  private loadingStageMessageCode = {
    CONTACTING: {
      code: 'CONTACTING',
      message: 'product.summary.loading.contacting'
    },
    PAYING: {
      code: 'PAYING',
      message: 'product.summary.loading.paying'
    },
    PROCESSING: {
      code: 'PROCESSING',
      message: 'product.summary.loading.processing'
    },
    SUCCESS: {
      code: 'SUCCESS',
      message: 'product.summary.loading.success'
    }
  };

  public async submit() {
    if (typeof (this.$refs.paymentProvider as any)?.validate === 'function') {
      const isValid = await (this.$refs.paymentProvider as any).validate();
      if (isValid) {
        if (typeof (this.$refs.paymentProvider as any)?.pay === 'function') {
          const payResult = await (this.$refs.paymentProvider as any).pay();
        }
      } else {
        return false;
      }
    }

    this.alertActive = false;
    this.tokenGenerationWarning = false;
    this.setTokenTimer(setTimeout(() => {
      this.resetLoadingStage();
      this.$dialog.open({ type: 'technical-issue', info: 'Payment: submit timed out', level: 'error' });
    }, this.timeout));
    if (typeof (this.$refs.paymentProvider as any)?.getToken === 'function') {
      this.updateLoadingStage(this.loadingStageMessageCode.CONTACTING.code);
      const token = await (this.$refs.paymentProvider as any).getToken();
      if (typeof token === 'string') {
        this.setTokenTimer();
        this.updateLoadingStage(this.loadingStageMessageCode.PAYING.code);
        this.sendToken(token);
        return true;
      }
      this.errorMessage = token.errorMessage;
      this.tokenGenerationWarning = true;
      this.paymentWarning();
    } else if (['BankTransfer', 'AgencyInvoicing'].includes(_.get(this.app.config, `paymentProviderConfig.${this.selectedPaymentProvider.frontEndConfigKey}.component`))) {
      this.updateLoadingStage(this.loadingStageMessageCode.PROCESSING.code);
      this.setTokenTimer();
      this.sendToken('', !_.isEmpty(this.proposal.proposalId) ? this.proposal.proposalId : this.proposal.invoiceId);
      Util.gtmLogEcommerceEvent(this, 'click', 'purchase', this.proposal);
      return true;
    } else {
      this.resetLoadingStage();
      this.$dialog.open({ type: 'technical-issue', info: 'Payment: no provider', level: 'error' });
    }
    return false;
  }

  get priceToPay() {
    return this.getInvoicesPriceToPay();
  }

  get totalTax() {
    return this.getInvoicesTotalTax();
  }

  get paymentProviderConfig() {
    return this.getPaymentProviderConfig(this.selectedPaymentProvider);
  }

  private onChangePaymentProvider(index) {
    this.selectedPaymentProvider = this.paymentProviders[index];
  }

  private getPaymentProviderConfig(provider) {
    if (!provider) {
      const singlePaymentProvider = _.find(this.paymentProviders, (p) => p.id === provider.id) || _.get(this.app.config, 'paymentProvider');
      return {
        name: this.$t(`proposal.payment.providerLabel.${singlePaymentProvider}`) || singlePaymentProvider,
        component: singlePaymentProvider,
        props: _.get(this.app.config, singlePaymentProvider)
      };
    }

    const config = {..._.get(this.app.config, `paymentProviderConfig.${provider.frontEndConfigKey}`)};
    config.name = this.$t(`proposal.payment.providerLabel.${provider.frontEndConfigKey}`) || config.name;
    return config;
  }

  private getProductLevelFactValueById(id: string) {
    return this.getFactValueByFactID(id);
  }

  private getPriceToPayAfterRefunds() {
    return this.priceToPay + (this.refunds - this.refundsTax);
  }

  private getTaxAfterRefunds() {
    return this.totalTax + this.refundsTax;
  }

  private isExternalPricing() {
    const code = this.proposal
      && this.proposal.products
      && this.proposal.products.length > 0
      && this.proposal.products[0].code;

    const product = _.find(this.products, (productEl) => productEl.code === code);
    return product?.allowExternalPricing;
  }

  private getPriceAfterRefunds() {
    return this.priceToPay + this.totalTax + this.refunds;
  }

  private getTaxPercentage() {
    return this.getTotalTaxPercentage();
  }

  private async checkRefunds() {
    const invoiceId = this.proposal.invoiceId;
    if (invoiceId) {
      const status = await PolicyService.getInvoiceStatus(invoiceId).catch((e: any) => {
        console.error(e.message || e);
      });
      const credits = _.get(status, 'data.data', []).filter((i) => i.entryType === 'CREDIT');
      let refunds = 0;
      let refundsTax = 0;
      credits.forEach((i) => {
        refunds += i.amount;
        refundsTax += i.taxAmount;
      });
      this.refunds = -refunds || 0;
      this.refundsTax = -refundsTax || 0;
    }
  }

  private getComponents(product: any) {
    const componentCodes = _.map(product.components, 'code');
    const prod = _.find(this.filteredProducts, (p: any) => {
      return _.get(p, 'productDescription.id') === product.productDescriptionId;
    });
    return _.filter(
        _.get(prod, 'productDescription.components', []),
        (comp: any) => {
          return _.includes(componentCodes, comp.code);
        },
    );
  }

  private updateLoadingStage(stage: string) {
    this.setLoadingText({ message: this.$t(this.loadingStageMessageCode[stage].message) });
  }

  private resetLoadingStage() {
    this.setLoadingText(null);
  }

  get filteredProducts() {
    const selectedCodes = _.map(this.proposal.products, 'code');
    return _.filter(this.getUniqueProducts(), (product: any) => {
      return selectedCodes.includes(product.code);
    });
  }

  private setTokenTimer(payload: any = null) {
    clearTimeout(this.tokenTimer);
    this.tokenTimer = payload;
  }

  private created() {
    this.initStep();
    this.setupPaymentCallback();
    if (this.app.config.showPaymentIssue !== undefined) {
      this.showPaymentIssue = this.app.config.showPaymentIssue;
    }
    const { subSegment }: any = this.$route.query;
    if (subSegment !== undefined) {
      this.showPaymentIssue = false;
    }
  }
  private mounted() {
    Util.gtmLogEcommerceEvent(this, 'page_load', 'beging_checkout', {});
    Util.gtmLogCustomEvent(this, 'page_load', 'quotebuyform_complete', {
      form_step: 6,
      form_name: 'payment'
    });
  }

  private setupPaymentCallback() {
    window.addEventListener('storage', (ev) => {
      this.setLoadingText(null);
      if (!ev || ev.key !== 'message') {
        return;
      }
      const message = ev.newValue ? JSON.parse(ev.newValue) : null;
      if (!message) {
        return;
      }

      if (message.result === 'approved' || !message.result) {
        const data = message.data;
        this.sendToken('', data, true);
      } else {
        if (typeof (this.$refs.paymentProvider as any)?.paymentFailed === 'function') {
          (this.$refs.paymentProvider as any).paymentFailed();
        }
        if (message.result === 'cancelled') {
          this.$dialog.open({ type: 'payment-cancelled', info: 'Payment cancelled' });
        } else {
          this.$dialog.open({ type: 'payment-declined', info: 'Payment declined' });
        }
      }
    });
  }

  private activated() {
    this.initStep();
    Util.gaLogPageView(this, `/payment+${sessionStorage.subSegment ? sessionStorage.subSegment : sessionStorage.targetSegment}`);
    this.$nextTick(() => {
      EventBus.$emit('stepper-idle');
    });
    GAUtil.ODLogPayment(this.app.isContinuation);
  }

  private deactivated() {
    EventBus.$emit('stepper-busy');
  }

  private initStep() {
    this.paymentProviders = this.getPaymentProviders();
    if (this.app.config.paymentProviderConfig && Object.keys(this.app.config.paymentProviderConfig).length) {
      this.paymentProviders = this.paymentProviders.filter((pp: PaymentProviderModel) => {
        const ppConfig: any = this.app.config.paymentProviderConfig[pp.frontEndConfigKey];
        let includeProvider: any = true;
        if (ppConfig && ppConfig.useOnSubSegment) {
          includeProvider = sessionStorage.subSegment;
        } else if (ppConfig && !ppConfig.useOnSubSegment) {
          includeProvider = !sessionStorage.subSegment;
        }
        return ppConfig && includeProvider;
      });
    }
    this.paymentProviders = this.paymentProviders.sort((provider) => _.get(this.app.config, `paymentProviderConfig.${provider.frontEndConfigKey}.default`, false) ? -1 : 0);
    this.selectedPaymentProvider = this.paymentProviders[0];
    this.checkRefunds();
  }

  private submitApplication() {
    this.setPaymentSkipped(true);
    Util.gaLogPaymentEvent(this, 'payment', Util.GA_PAYMENT_STATUSES_TYPE.SKIPPED, Util.GA_PAYMENT_STATUSES_RESULT.SKIPPED);
    this.sendToCRM();
    this.proposal.products.forEach(async (p) => {
      await PolicyService.postPolicynote(p.policyId);
    });
    this.proceedToConfirmation('', false);
  }

  private proceedToConfirmation(type: string = '', completed: boolean = true) {
    const query: any = Object.assign(
      this.$route.query,
      {
        language: this.app.language,
        type,
        completed
      }
    );
    this.$router.push({ name: 'confirmation', query }, undefined, (error) => {
      this.resetLoadingStage();
      this.$dialog.open({ type: 'redirection-issue-payment', info: 'Payment: redirection to confirmation failed', level: 'error' });
    });
  }

  private paymentWarning() {
    this.paymentCleanup();
    if (!this.alertActive) {
      this.alertActive = true;
      this.$dialog.open({
        icon: this.getIcon,
        text: this.getErrorMessage,
        buttons: [
          {
            type: 'main',
            text: this.$t('button.ok'),
            onClick: () => {
              this.setAlertActive(false);
            }
          }
        ],
        info: 'Payment: Card / Payment Error',
        data: this.getErrorMessage
      });
    }
  }

  private paymentCleanup() {
    this.resetLoadingStage();
    this.setTokenTimer();
    this.submittedPayment = false;
  }

  private technicalError(error, data) {
    this.paymentCleanup();
    this.$dialog.open({ type: 'technical-issue', info: error, data });
  }

  private setAlertActive(value) {
    this.alertActive = value;
  }

  get getErrorMessage() {
    return this.errorMessage;
  }

  get getIcon() {
    if (this.shiftPaymentError) {
      return this.cms.theme.errorIcon;
    } else if (this.tokenGenerationWarning) {
      return this.cms.theme.ccWarningIcon;
    }
  }

  private async sendToken(token: string, ref?: string, external: boolean = false) {
    const paymentRequest = this.getPaymentRequest(this.selectedPaymentProvider, token, ref);

    let gatewayError = false;
    const paymentResponse = await paymentRequest.catch((e: any) => {
      if (e.error && e.error.code === 402) {
        console.error(e.error.message || e);
        this.resetLoadingStage();
        gatewayError = true;
      } else if (!e.error || e.error.code !== 401) {
        console.error((e.error && e.error.message) || e.message || e);
        this.resetLoadingStage();
        this.technicalError('Payment: Shift (error)', e);
      }
    });
    if (paymentResponse === '401') {
      setTimeout(this.sendToken.bind(this, token), 1000);
      return;
    }
    let paymentFailed = false;
    if (paymentResponse && paymentResponse.status === 200) {
      const paymentStatus = paymentResponse.data.data.status || paymentResponse.data.status;
      if (paymentStatus === 'SETTLED' || paymentStatus === 'PAID') {
        this.updateLoadingStage(this.loadingStageMessageCode.SUCCESS.code);
        this.sendSuccessPaymentToPardot();
        Util.gaLogPaymentEvent(this, 'payment', Util.GA_PAYMENT_STATUSES_TYPE.SUCCESS, Util.GA_PAYMENT_STATUSES_RESULT.PAID);
        // Reverse change done for PD-2415, will fix together with agency invoicing later.
        // this.proceedToConfirmation(this.selectedPaymentProvider.frontEndConfigKey);
        this.proceedToConfirmation();
      } else if (['BANK_TRANSFER', 'AGENCY_INVOICING'].includes(this.selectedPaymentProvider.name)) {
          // in order to show the last step screen.
          this.proceedToConfirmation(this.selectedPaymentProvider.frontEndConfigKey);
      } else if (paymentStatus === 'PAYMENT_FAILED') {
        this.shiftPaymentError = true;
        this.errorMessage = this.$t('proposal.payment.error.genericIssue');
        this.paymentWarning();
        paymentFailed = true;
      } else {
        this.technicalError('Payment: Shift (200)', paymentResponse);
        paymentFailed = true;
      }
    } else if (gatewayError || (paymentResponse && paymentResponse === '402')) {
      if (_.isEmpty(token) && ref && !external) {
        // Reverse change done for PD-2415, will fix together with agency invoicing later.
        // this.proceedToConfirmation(this.selectedPaymentProvider.frontEndConfigKey);
        return this.proceedToConfirmation();
      }
      this.shiftPaymentError = true;
      this.errorMessage = this.$t('proposal.payment.error.gatewayIssue');
      this.paymentWarning();
      paymentFailed = true;
    } else {
      this.technicalError('Payment: Shift (undefined)', paymentResponse);
      paymentFailed = true;
    }

    if (paymentFailed) {
      const paymentProviderAny = this.$refs.paymentProvider as any;
      if (typeof paymentProviderAny?.paymentFailed === 'function') {
        paymentProviderAny.paymentFailed();
      }
    }
  }

  // this method need to be move to a shared place.
  get getAfterReviewInterpolationObject() {
    const proposalFacts: any = {};
    proposalFacts.applicantFullName = `${this.proposal.contactInfo.givenName} ${this.proposal.contactInfo.familyName}`;

    try {
      // only gets facts from the first product

      const productTree = this.getAllCustomProductTrees();
      if (productTree && productTree.length > 0 && productTree[0].allFacts) {
        const firstProduct = productTree[0];
        firstProduct.allFacts.forEach((fact) => {
          proposalFacts[fact.id.replaceAll('.', '_').replaceAll(':', '_')] = fact.currentValue;
        });
        if (firstProduct.components) {
          firstProduct.components.forEach((component) => {
            if (component.allFacts) {
              component.allFacts.forEach((fact) => {
                proposalFacts[fact.id.replaceAll('.', '_').replaceAll(':', '_')] = fact.currentValue;
              });
            }
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
    return proposalFacts;
  }

  private getPaymentRequest(paymentProvider: any, token: string, ref?: string) {
    if (['BANK_TRANSFER', 'AGENCY_INVOICING'].includes(paymentProvider.name)) {
      return PolicyService.namedPostPolicyInvoiceSettle(this.proposal.invoiceId, paymentProvider.name, token);
    } else if (_.isEmpty(token) && ref) {
      return PolicyService.postPolicyInvoiceSettle(this.proposal.invoiceId, this.selectedPaymentProvider.id, null, ref);
    } else {
      return PolicyService.postPolicyInvoiceSettle(this.proposal.invoiceId, this.selectedPaymentProvider.id, token);
    }
  }

  private async sendSuccessPaymentToPardot() {
    if (this.app.originUrl && (this.app.isRenewal || this.app.isContinuation)) {
      const usedObj = {
        email: this.proposal.contactInfo.email,
        ApplicationStatus: '',
        mobilePhone: this.getFactValueByFactID('applicant.phone')
      };
      usedObj[this.app.isRenewal ? 'RenewalLink' : 'ContinuationLink'] = encodeURIComponent(this.app.originUrl);
      usedObj.ApplicationStatus = this.app.isRenewal ? RENEWAL_LINK_PAID : CONTINUATION_LINK_PAID;
      if (this.app.isRenewal) {
        _.get(await this.$pardot(), Util.PARDOT_ACTIONS.REPORT_RENEWAL_PAID, Util.PARDOT_ACTIONS.PLACEHOLDER_METHOD)(usedObj)
          .then(() => {
            this.setPardotAvailability(true);
          })
          .catch(() => {
            this.setPardotAvailability(false);
          });
      } else {
        _.get(await this.$pardot(), Util.PARDOT_ACTIONS.REPORT_CONTINUATION_PAID, Util.PARDOT_ACTIONS.PLACEHOLDER_METHOD)(usedObj)
          .then(() => {
            this.setPardotAvailability(true);
          })
          .catch(() => {
            this.setPardotAvailability(false);
          });
      }
    }
  }

  private onError() {
    this.shiftPaymentError = true;
    this.errorMessage = this.$t('proposal.payment.error.invalidId');
    this.paymentWarning();
  }

  private async sendToCRM() {
    _.get(await this.$pardot(), Util.PARDOT_ACTIONS.REPORT_PAYMENT_ISSUE, Util.PARDOT_ACTIONS.PLACEHOLDER_METHOD)()
      .then(() => {
        this.setPardotAvailability(true);
      })
      .catch((info) => {
        this.setPardotAvailability(false);
        this.$dialog.open({ type: 'technical-issue', info, level: 'error' });
      });
  }

  private hasTaxInfo() {
    return !!this.$t('product.summary.tax.comment');
  }
}

