




































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import Util from '@/utils/Util';
import PaymentOptions from '@/components/layouts/PaymentOptions.vue';

declare var eWAY: any;

@Component({
  name: 'Eway',
  components: {
    PaymentOptions,
  }
})
export default class EWay extends Vue {
  @Prop() private config: any;
  @Prop() private submitApplication!: any;
  @State private app: any;
  @Action('app/setLoadingText') private setLoadingText: any;
  private secureFieldCode: string = '';
  private paymentFieldsReady: boolean = false;
  private cardErrors: any = [];

  get screenWidth() {
    return this.$vuetify.breakpoint.width;
  }

  get Visa() {
    return '/coverhub-portal/images/Visa.svg';
  }

  get Mastercard() {
    return '/coverhub-portal/images/Mastercard.svg';
  }

  get Amex() {
    return '/coverhub-portal/images/Amex.svg';
  }

  public async getToken() {
    return new Promise((r) => {
      setTimeout(() => {
        const response = (this.cardErrors.length > 0) ? {
          errorMessage: `Invalid ${this.cardErrors[0]}. Please try again.`
        } : this.secureFieldCode;
        r(response);
      }, 1000);
    });
  }


  private secureFieldCallback(event) {
    if (!event.fieldValid) {
      this.technicalError('EWay Payment Error.', event.errors);
    } else if (!event.valueIsValid) {
      this.addCardErrors(event.targetField);
    } else {
      this.removeCardErrors(event.targetField);
      this.secureFieldCode = event.secureFieldCode;
    }
  }

  private addCardErrors(field) {
    if (this.cardErrors.indexOf(field)) {
      this.cardErrors.push(field);
    }
  }

  private removeCardErrors(field) {
    this.cardErrors = this.cardErrors.filter((e) => e !== field);
  }

  private getTextBoxStyles() {
    return [
      'box-sizing: border-box',
      'padding: 9px',
      'height: 61px',
      'font-size: 1.125em',
      `color: ${this.$colors.fontsPrimary.replace('#', '%23')} !important`,
      'border:solid 1px rgb(212, 211, 220)',
      'border-radius: 5px'
    ];
  }

  private setupEWay() {
    Util.initEWay().then(() => {
      (this.$refs.ewayName as HTMLElement).innerHTML = '';
      (this.$refs.ewayCard as HTMLElement).innerHTML = '';
      (this.$refs.ewayExpiry as HTMLElement).innerHTML = '';
      (this.$refs.ewayCVN as HTMLElement).innerHTML = '';
      return Promise.all([
        eWAY.setupSecureField({
          publicApiKey: this.config.publicApiKey,
          fieldDivId: 'eway-secure-field-name',
          fieldType: 'name',
          styles: this.getTextBoxStyles().join(';')
        }, this.secureFieldCallback),
        eWAY.setupSecureField({
          publicApiKey: this.config.publicApiKey,
          fieldDivId: 'eway-secure-field-card',
          fieldType: 'card',
          styles: this.getTextBoxStyles().join(';')
        }, this.secureFieldCallback),
        eWAY.setupSecureField({
          publicApiKey: this.config.publicApiKey,
          fieldDivId: 'eway-secure-field-expiry',
          fieldType: 'expirytext',
          styles: this.getTextBoxStyles().join(';')
        }, this.secureFieldCallback),
        eWAY.setupSecureField({
          publicApiKey: this.config.publicApiKey,
          fieldDivId: 'eway-secure-field-cvn',
          fieldType: 'cvn',
          styles: this.getTextBoxStyles().join(';')
        }, this.secureFieldCallback),
      ]).then(() => {
        this.paymentFieldsReady = true;
      });
    });
  }

  private mounted() {
    this.setupEWay();
    this.addCardErrors('card');
    this.addCardErrors('expiry');
    this.addCardErrors('cvn');
  }

  private technicalError(error, data) {
    // this.$dialog.open({ type: 'technical-issue', info: error, data });
    // this.setupEWay();
  }

  private paymentFailed() {
    this.setupEWay();
  }
}

